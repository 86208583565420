export var TaskType;
(function (TaskType) {
    TaskType[TaskType["General"] = 0] = "General";
    TaskType[TaskType["Thinning"] = 1] = "Thinning";
    TaskType[TaskType["Weeding"] = 2] = "Weeding";
    TaskType[TaskType["Fleecing"] = 3] = "Fleecing";
    TaskType[TaskType["BedShaping"] = 4] = "BedShaping";
    TaskType[TaskType["Planting"] = 5] = "Planting";
    TaskType[TaskType["Transplanting"] = 6] = "Transplanting";
    TaskType[TaskType["Irrigation"] = 7] = "Irrigation";
    TaskType[TaskType["SoilSample"] = 8] = "SoilSample";
    TaskType[TaskType["Chemical"] = 9] = "Chemical";
})(TaskType || (TaskType = {}));
export const TaskTypeNames = {
    [TaskType.General]: "task.type.general",
    [TaskType.Thinning]: "task.type.thinning",
    [TaskType.Weeding]: "task.type.weeding",
    [TaskType.Fleecing]: "task.type.fleecing",
    [TaskType.BedShaping]: "task.type.bed-shaping",
    [TaskType.Planting]: "task.type.planting",
    [TaskType.Transplanting]: "task.type.transplanting",
    [TaskType.Irrigation]: "task.type.irrigation",
    [TaskType.SoilSample]: "task.type.soil-sample",
    [TaskType.Chemical]: "task.type.chemical",
};
