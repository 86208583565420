import dayjs from "dayjs";
export var DuplicateResult;
(function (DuplicateResult) {
    /**
     * Add this mutation to the queue, and keep the other one
     */
    DuplicateResult[DuplicateResult["KeepBoth"] = 0] = "KeepBoth";
    /**
     * Add this mutation to the queue, and delete the old one
     */
    DuplicateResult[DuplicateResult["KeepIncoming"] = 1] = "KeepIncoming";
    /**
     * Discard this mutation (don't queue), keep the old one
     */
    DuplicateResult[DuplicateResult["DiscardIncoming"] = 2] = "DiscardIncoming";
    /**
     * Discard both mutations
     */
    DuplicateResult[DuplicateResult["DiscardBoth"] = 3] = "DiscardBoth";
})(DuplicateResult || (DuplicateResult = {}));
export class MonthlyPageParam {
    constructor(year, month) {
        this.year = year;
        this.month = month;
    }
    get key() {
        return `${this.year}-${this.month}`;
    }
    get startDateStr() {
        return `${this.year}-${this.month}-01`;
    }
    get endDateStr() {
        const date = new Date(this.year, this.month - 1, 1);
        date.setMonth(date.getMonth() + 1);
        date.setDate(date.getDate() - 1);
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    }
    static fromKey(key) {
        const [year, month] = key.split("-");
        return new MonthlyPageParam(parseInt(year), parseInt(month));
    }
    static fromDate(date) {
        return new MonthlyPageParam(date.getFullYear(), date.getMonth() + 1);
    }
    static fromDateString(datestr) {
        const date = dayjs(datestr);
        return new MonthlyPageParam(date.year(), date.month() + 1);
    }
    next() {
        if (this.month === 12) {
            return new MonthlyPageParam(this.year + 1, 1);
        }
        else {
            return new MonthlyPageParam(this.year, this.month + 1);
        }
    }
    prev() {
        if (this.month === 1) {
            return new MonthlyPageParam(this.year - 1, 12);
        }
        else {
            return new MonthlyPageParam(this.year, this.month - 1);
        }
    }
}
